var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "meterial_detail" },
    [
      _c("div", { staticClass: "material_content" }, [
        _c("div", { staticClass: "left_video" }, [
          _c("div", { staticClass: "video_box" }, [
            _c("div", {
              staticClass: "xgplayer-is-fullscreen",
              attrs: { id: "materialDetail" }
            }),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.watchCount != 0,
                    expression: "watchCount != 0"
                  }
                ],
                staticClass: "hover_effect"
              },
              [
                _c("span", {
                  staticClass: "iconfont icon-fenxiang",
                  on: { click: _vm.copyPath }
                }),
                _c("span", {
                  staticClass: "iconfont icon-xiazai",
                  on: { click: _vm.videoDownloadConfirm }
                }),
                (_vm.collectif == 0
                ? true
                : false)
                  ? _c("span", {
                      staticClass: "iconfont icon-weishoucang",
                      on: {
                        click: function($event) {
                          return _vm.addCollet(_vm.currentId)
                        }
                      }
                    })
                  : _c("span", {
                      staticClass: "iconfont icon-shoucang",
                      on: {
                        click: function($event) {
                          return _vm.addCollet(_vm.currentId)
                        }
                      }
                    }),
                _vm.ifcopy
                  ? _c(
                      "div",
                      {
                        staticClass: "existed_tag",
                        staticStyle: {
                          top: "-55px",
                          left: "-28%",
                          "letter-spacing": "2px"
                        }
                      },
                      [_vm._v(" 已复制链接 ")]
                    )
                  : _vm._e()
              ]
            )
          ]),
          _c("div", { staticClass: "bottom_info" }, [
            _c("div", { staticClass: "titleandButtom" }, [
              _c(
                "div",
                [
                  _c(
                    "el-tooltip",
                    {
                      directives: [
                        { name: "delTabIndex", rawName: "v-delTabIndex" }
                      ],
                      attrs: {
                        "popper-class": "my_material_tooltip",
                        content: _vm.currentName,
                        placement: "bottom-start"
                      }
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "currentName",
                          staticStyle: { opacity: "0.8" },
                          on: {
                            click: function($event) {
                              return _vm.gotoDetail(_vm.currentId)
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.currentName) + " ")]
                      )
                    ]
                  ),
                  _c("span")
                ],
                1
              ),
              _c(
                "div",
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "gojianji",
                      style: { opacity: _vm.watchCount == 0 ? "0.6" : "1" },
                      attrs: { disabled: _vm.watchCount == 0, type: "primary" }
                    },
                    [_vm._v("去剪辑")]
                  )
                ],
                1
              )
            ]),
            _c(
              "p",
              {
                staticStyle: {
                  "font-size": "16px",
                  color: "rgba(255, 255, 255, 0.8)"
                }
              },
              [_vm._v(" " + _vm._s(_vm.currentDate.substr(0, 10)) + " ")]
            ),
            _c("div", { staticClass: "list_intro" }, [
              _c("div", { staticClass: "title" }, [
                _vm._v(" " + _vm._s(_vm.introduce) + " ")
              ])
            ]),
            _c(
              "p",
              {
                staticStyle: {
                  color: "rgba(255, 255, 255, 0.8)",
                  "font-size": "16px",
                  "margin-top": "1vh",
                  "margin-bottom": "1vh"
                }
              },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.quanxian != "" && _vm.quanxian != null
                        ? "商用权限：" + _vm.quanxian
                        : ""
                    ) +
                    " "
                )
              ]
            ),
            _c("div", { staticClass: "scroll" }, [
              _c(
                "div",
                { staticClass: "tagList" },
                _vm._l(_vm.currentTag, function(item) {
                  return _c("span", { key: item.tag_id, staticClass: "tag" }, [
                    _vm._v(_vm._s(item.tag_name))
                  ])
                }),
                0
              )
            ])
          ])
        ]),
        _c("div", { staticClass: "right_list" }, [
          _c("div", { staticClass: "top" }, [
            _c(
              "span",
              {
                staticClass: "title",
                style: { cursor: _vm.watchCount == 0 ? "no-drop" : "" }
              },
              [_vm._v("观看列表(" + _vm._s(_vm.watchCount) + ")")]
            ),
            _c(
              "div",
              { staticStyle: { display: "flex", "align-items": "center" } },
              [
                _c("el-switch", {
                  staticStyle: { "margin-left": "10px" },
                  attrs: {
                    "inactive-text": "自动播放",
                    "active-color": "rgba(42, 156, 255, 0.5)",
                    "inactive-color": "#2D2C39",
                    disabled: _vm.watchCount == 0
                  },
                  on: { change: _vm.autoplay },
                  model: {
                    value: _vm.value,
                    callback: function($$v) {
                      _vm.value = $$v
                    },
                    expression: "value"
                  }
                }),
                _c(
                  "el-dropdown",
                  {
                    staticStyle: { margin: "0 5px 0px 15px" },
                    attrs: { trigger: "click" },
                    on: {
                      command: _vm.activeD,
                      "visible-change": _vm.showorhidden
                    }
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "el-dropdown-link",
                        class: _vm.watchCount != 0 ? "abled" : "disabled",
                        style: { background: _vm.bgcColor }
                      },
                      [_vm._v(" ••• ")]
                    ),
                    _c(
                      "el-dropdown-menu",
                      {
                        staticClass: "mydropdown",
                        attrs: { slot: "dropdown" },
                        slot: "dropdown"
                      },
                      [
                        _c("el-dropdown-item", { attrs: { command: "a" } }, [
                          _c("i", {
                            staticClass: "iconfont icon-daoxu1",
                            staticStyle: { "font-size": "12px" }
                          }),
                          _vm._v(" 倒序")
                        ]),
                        _c("el-dropdown-item", { attrs: { command: "b" } }, [
                          _c("i", {
                            staticClass: "iconfont icon-qingkongliebiao",
                            staticStyle: { "font-size": "12px" }
                          }),
                          _vm._v("清空列表")
                        ])
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _c(
            "div",
            { staticClass: "item_container" },
            _vm._l(_vm.watchListData, function(item) {
              return _c(
                "div",
                {
                  key: item.ip_id,
                  class: [
                    "item",
                    _vm.active == item.ip_id ? "active_item" : ""
                  ],
                  on: {
                    click: function($event) {
                      return _vm.Selected(
                        item.ip_id,
                        item.url,
                        _vm.copy_url,
                        item.collect_if,
                        item.name,
                        item.upload_time,
                        item.introduce,
                        item.commercial_licenses,
                        item.tag_list
                      )
                    },
                    mouseleave: function($event) {
                      return _vm.unSelect(item.ip_id)
                    },
                    mouseenter: function($event) {
                      return _vm.Select(item.ip_id)
                    }
                  }
                },
                [
                  _c("div", { staticClass: "post_outer" }, [
                    _c("div", { staticClass: "item_video" }, [
                      _c("img", {
                        style:
                          _vm.currentUrl == item.url
                            ? { opacity: 0.5 }
                            : { opacity: 1 },
                        attrs: { src: item.cover_img, id: item.ip_id }
                      }),
                      !item.cover_img
                        ? _c("i", { staticClass: "iconfont icon-shipin1" })
                        : _vm._e(),
                      _c("span", { staticClass: "duration" }, [
                        _vm._v(_vm._s(item.duration))
                      ])
                    ]),
                    _c("div", { staticClass: "item_info" }, [
                      _c(
                        "div",
                        { staticClass: "title" },
                        [
                          _c(
                            "el-tooltip",
                            {
                              attrs: {
                                placement: "bottom-end",
                                "popper-class": "my_material_tooltip",
                                content: item.name
                              }
                            },
                            [
                              _c("div", { staticClass: "one_line_ellipsis" }, [
                                _vm._v(_vm._s(item.name))
                              ])
                            ]
                          ),
                          item.showCancel
                            ? _c(
                                "div",
                                {
                                  staticClass: "delete",
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      return _vm.Delete(item.ip_id)
                                    }
                                  }
                                },
                                [
                                  _c("i", {
                                    staticClass:
                                      "iconfont icon-a-guankanliebiaodeguanbicha"
                                  })
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      item.introduce
                        ? _c(
                            "div",
                            { staticClass: "context one_line_ellipsis" },
                            [_vm._v(_vm._s("简介：" + item.introduce))]
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        { staticClass: "tagList one_line_ellipsis" },
                        _vm._l(item.tag_list, function(item) {
                          return _c(
                            "span",
                            { key: item.id, staticClass: "tag" },
                            [_vm._v(_vm._s(item.tag_name))]
                          )
                        }),
                        0
                      )
                    ])
                  ])
                ]
              )
            }),
            0
          )
        ])
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "下载",
            visible: _vm.downloadDialog,
            width: "461px",
            top: "30vh",
            "custom-class": "material-dialog",
            "show-close": true
          },
          on: {
            "update:visible": function($event) {
              _vm.downloadDialog = $event
            }
          }
        },
        [
          _c(
            "span",
            { staticStyle: { display: "block", "margin-bottom": "21px" } },
            [_vm._v("分辨率：")]
          ),
          _c(
            "div",
            { staticClass: "radio_check" },
            [
              _vm.resolutions.length == 1
                ? _c(
                    "el-radio",
                    { staticClass: "disable_radio", attrs: { label: "标清" } },
                    [_vm._v("标清")]
                  )
                : _vm._e(),
              _vm._l(_vm.resolutions, function(item, index) {
                return _c(
                  "el-radio",
                  {
                    key: index,
                    attrs: { label: item.url },
                    model: {
                      value: _vm.system,
                      callback: function($$v) {
                        _vm.system = $$v
                      },
                      expression: "system"
                    }
                  },
                  [_vm._v(_vm._s(item.resolution))]
                )
              })
            ],
            2
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.downloadConfirm }
                },
                [_vm._v("确 定")]
              ),
              _c(
                "el-button",
                {
                  staticStyle: { "margin-right": "30px" },
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      _vm.downloadDialog = false
                    }
                  }
                },
                [_vm._v("取 消")]
              )
            ],
            1
          )
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "清空",
            visible: _vm.clearAllDialog,
            width: "461px",
            top: "30vh",
            "custom-class": "clearAll-dialog",
            "show-close": true
          },
          on: {
            "update:visible": function($event) {
              _vm.clearAllDialog = $event
            }
          }
        },
        [
          _c(
            "span",
            { staticStyle: { display: "block", "margin-bottom": "21px" } },
            [_vm._v("确认清空观看列表？")]
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.clearAllConfirm }
                },
                [_vm._v("确 定")]
              ),
              _c(
                "el-button",
                {
                  staticStyle: { "margin-right": "30px" },
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      _vm.clearAllDialog = false
                    }
                  }
                },
                [_vm._v("取 消")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }