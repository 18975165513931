<template>
  <div class="meterial_detail">
    <div class="material_content">
      <!-- 左侧视频跟底部信息 -->
      <div class="left_video">
        <!-- 视频 -->
        <div class="video_box" style="">
          <div id="materialDetail" class="xgplayer-is-fullscreen"/>
          <!-- 复制链接，下载，收藏按钮 -->
          <div v-show="watchCount != 0" class="hover_effect">
            <span class="iconfont icon-fenxiang" @click="copyPath"></span>
            <span
              class="iconfont icon-xiazai"
              @click="videoDownloadConfirm"
            ></span>
            <span
              class="iconfont icon-weishoucang"
              @click="addCollet(currentId)"
              v-if="collectif == 0 ? true : false"
            ></span>
            <span
              class="iconfont icon-shoucang"
              @click="addCollet(currentId)"
              v-else
            ></span>
            <div
              class="existed_tag"
              style="top: -55px; left: -28%; letter-spacing: 2px"
              v-if="ifcopy"
            >
              已复制链接
            </div>
          </div>
        </div>
        <!-- 底部信息 -->
        <div class="bottom_info">
          <!-- 名称跟去剪辑按钮 -->
          <div class="titleandButtom">
            <div>
               <el-tooltip
               v-delTabIndex
               popper-class="my_material_tooltip"
              :content="currentName"
              placement="bottom-start"
              >
                <span
                style=" opacity: 0.8"
                class="currentName"
                @click="gotoDetail(currentId)"
                >{{ currentName }}
              </span>
              </el-tooltip>

              <span></span>
            </div>
            <div>
              <el-button
                :style="{ opacity: watchCount == 0 ? '0.6' : '1' }"
                :disabled="watchCount == 0"
                class="gojianji"
                type="primary"
                >去剪辑</el-button
              >
            </div>
          </div>
          <p style="font-size: 16px; color: rgba(255, 255, 255, 0.8)">
            {{ currentDate.substr(0, 10) }}
          </p>
          <div class="list_intro">
            <div class="title">
              {{ introduce }}
            </div>
          </div>
          <p
            style="
              color: rgba(255, 255, 255, 0.8);
              font-size: 16px;
              margin-top: 1vh;
              margin-bottom: 1vh;
            "
          >
            {{ quanxian != "" && quanxian != null ? "商用权限：" + quanxian : "" }}
          </p>
          <div class="scroll">
            <!-- 标签列表 -->
            <div class="tagList">
              <span v-for="item in currentTag" :key="item.tag_id" class="tag">{{ item.tag_name }}</span>
            </div>
          </div>
        </div>
      </div>
      <!-- 右侧观看列表 -->
      <div class="right_list">
        <!-- 头部按钮 跟统计-->
        <div class="top">
          <span :style="{ cursor: watchCount == 0 ? 'no-drop' : '' }" class="title">观看列表({{ watchCount }})</span>
          <div style="display:flex;align-items:center">
            <el-switch
             @change="autoplay"
              style="margin-left: 10px"
              inactive-text="自动播放"
              v-model="value"
              active-color="rgba(42, 156, 255, 0.5)"
              inactive-color="#2D2C39"
              :disabled="watchCount == 0"
            >
            </el-switch>
            <el-dropdown
             @command="activeD"
              @visible-change="showorhidden"
              trigger="click"
              style="margin: 0 5px 0px 15px"
            >
              <span :class="watchCount != 0 ? 'abled' : 'disabled'" :style="{background: bgcColor}" class="el-dropdown-link">
                •••
              </span>
              <el-dropdown-menu class="mydropdown" slot="dropdown">
                <el-dropdown-item command="a"> <i  style="font-size: 12px"  class="iconfont icon-daoxu1" ></i> 倒序</el-dropdown-item>
                <el-dropdown-item command="b"> <i  style="font-size: 12px"  class="iconfont icon-qingkongliebiao" ></i>清空列表</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
        <!-- 播放列表区域 -->
        <div class="item_container">
          <!-- 每一项 -->
          <div
            :class="['item',active == item.ip_id?'active_item':'']"
            v-for="item in watchListData"

            :key="item.ip_id"
            @click="
              Selected(
                item.ip_id,
                item.url,
                copy_url,
                item.collect_if,
                item.name,
                item.upload_time,
                item.introduce,
                item.commercial_licenses,
                item.tag_list
              )
            "
            @mouseleave="unSelect(item.ip_id)"
            @mouseenter="Select(item.ip_id)"
          >
          <div class="post_outer">
            <div class="item_video">
              <img :src="item.cover_img" :id="item.ip_id" :style="currentUrl == item.url ? { opacity: 0.5 } : { opacity: 1 }"/>
              <i class="iconfont icon-shipin1" v-if="!item.cover_img"></i>
              <span class="duration">{{item.duration}}</span>
            </div>
            <div class="item_info">
              <div class="title">
                <el-tooltip
                  placement="bottom-end"
                  popper-class="my_material_tooltip"
                  :content="item.name"
                  >
                  <div class="one_line_ellipsis">{{ item.name }}</div>
                </el-tooltip>

                <!-- 删除按钮 -->
                <div v-if="item.showCancel" class="delete" @click.stop="Delete(item.ip_id)">
                  <i class="iconfont icon-a-guankanliebiaodeguanbicha"></i>
                </div>
              </div>
              <div class="context one_line_ellipsis" v-if="item.introduce">{{ '简介：'+ item.introduce}}</div>
              <!-- 标签列表 -->
              <div class="tagList one_line_ellipsis">
                <span v-for="item in item.tag_list" :key="item.id" class="tag">{{ item.tag_name }}</span>
              </div>
            </div>
          </div>
          </div>
        </div>
      </div>

    </div>
    <!-- 对话框 -->
    <el-dialog
      title="下载"
      :visible.sync="downloadDialog"
      width="461px"
      top="30vh"
      custom-class="material-dialog"
      :show-close="true"
    >
      <span style="display: block; margin-bottom: 21px">分辨率：</span>
      <div class="radio_check">
        <el-radio
          label="标清"
          v-if="resolutions.length == 1"
          class="disable_radio"
          >标清</el-radio
        >
        <el-radio
          v-for="(item, index) in resolutions"
          :key="index"
          v-model="system"
          :label="item.url"
          >{{ item.resolution }}</el-radio
        >
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="downloadConfirm">确 定</el-button>
        <el-button
          type="primary"
          @click="downloadDialog = false"
          style="margin-right: 30px"
          >取 消</el-button
        >
      </span>
    </el-dialog>
    <!-- 清空对话框 -->
    <el-dialog
      title="清空"
      :visible.sync="clearAllDialog"
      width="461px"
      top="30vh"
      custom-class="clearAll-dialog"
      :show-close="true"
    >
      <span style="display: block; margin-bottom: 21px"
        >确认清空观看列表？</span
      >

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="clearAllConfirm">确 定</el-button>
        <el-button
          type="primary"
          @click="clearAllDialog = false"
          style="margin-right: 30px"
          >取 消</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Player from "xgplayer";
import "../assets/.xgplayer/skin/index.js";
import $ from "jquery";
export default {
  name: "materialDetail",
  // inject:['reload'],
  components: {
  },
  data() {
    return {
      player: null,
      ifcopy: false, //是否复制
      downloadDialog: false, //显示隐藏下载对话框
      resolutions: [], //下载的分辨率
      clearAllDialog: false,
      system: "", //选中的分辨率
      keyWordsVisible: false, //加号跟输入框的切换显示
      keyWordsValue: "", // 输入框值
      command: "", //选中的添加标签还是添加关键词
      keyWordsExistedTag: false, //显示隐藏关键词已经存在
      selectTitle: "基本信息",
      switchContent: true,
      tip: "", //提示词
      watchCount: 0, //观看列表统计
      watchListUrl: [], //观看列表的url
      watchListData: [], //播放列表数据
      currentUrl: "", //当前视频url
      collectif: "", //当前视频是否收藏
      currentId: "", //当前视频id
      currentName: "", //当前视频名称
      currentDate: "", //当前视频日期
      introduce: "", //当前视频简介
      quanxian: "", //当前视频权限
      copy_url: "",
      currentTag: [], //当前视频标签
      orderLog: 0, //顺序倒序按钮切换
      active: "", //控制每一项的选中
      value: false, //自动播放绑定的值
      bgcColor: "", //控制三个点点击的背景色
    };
  },
  methods: {
    // 点击加号 将背景颜色改变
    showorhidden(e) {
      if (e) {
        this.bgcColor = "#2D2C39";
      } else {
        this.bgcColor = "";
      }
    },
    // 分享链接按钮
    copyPath() {
      if (this.player == null) {
      } else {
        this.ifcopy = true;
        this.copyUrl();
        setTimeout(() => {
          this.ifcopy = false;
        }, 3000);
      }
    },
    // 复制链接事件
    copyUrl() {
      var input = document.createElement("input"); // js创建一个input输入框
      input.value =encodeURI(this.copy_url); // 将需要复制的文本赋值到创建的input输入框中
      document.body.appendChild(input); // 将输入框暂时创建到实例里面
      input.select(); // 选中输入框中的内容
      document.execCommand("Copy"); // 执行复制操作
      document.body.removeChild(input);

      // 最后删除实例中临时创建的input输入框，完成复制操作
    },
    // 添加/取消收藏
    addCollet(id) {
      if (this.player == null) {
      } else {
        // 根据当前播放视频的id
        let formData = new FormData();
        formData.append("ip_id", id);
        formData.append("type", 1);
        this.$http({
          method: "POST",
          url: "vw_material/add_my_collect",
          data: formData,
        })
          .then((res) => {
            if (res.data.msg == "收藏成功") {
              this.collectif = 2;
            }
            if (res.data.msg == "取消收藏成功") {
              this.collectif = 0;
            }
          })
          .catch((error) => console.log(error));
      }
    },
    // 下载按钮
    videoDownloadConfirm() {
      if (this.player == null) {
      } else {
        // 弹出下载框
        for (let item of this.watchListData) {
          // 根据当前播放视频的id
          if (this.currentUrl == item.url) {
            console.log("1");
            let formData = new FormData();
            formData.append("ip_id", item.ip_id);
            this.$http({
              url: "/upload/get_download_res",
              method: "POST",
              data: formData,
            })
              .then((res) => {
                if (res.data.status == 0) {
                  this.resolutions = res.data.result;
                  this.system = res.data.result[0].url;
                  this.downloadDialog = true;
                }
              })
              .catch((error) => console.log(error));
          }
        }
      }
    },
    // 下载框确认按钮
    downloadConfirm() {
      // 切换视频配置源 然后视频下载。
      let link = document.createElement("a");
      link.href = this.system; //链接
      document.body.appendChild(link); //a标签加到body
      link.click(); //模拟a标签点击
      document.body.removeChild(link); // 下载完成移除元素
      this.downloadDialog = false;
    },
    // 顺序
    autoplay(e) {
     if(e){
       this.player.play()
     }
    },
    // 选择倒序或者清空列表
    activeD(e){
     if(e=='a'){
         if (this.watchListData.length != 0) {
        this.orderLog = 1;
        console.log(this.watchListData);
        this.watchListData.reverse();
      }
     }
     if(e=='b'){
       this.clearAll()
     }
    },
    // 请求观看列表
    getWatchList() {
      // 销毁当前大的播放器
      this.destory();
      // 请求观看列表
      this.$http({
        url: "vw_material/watch_record_list",
        method: "GET",
      })
        .then((res) => {
          // console.log("观看列表数据", res.data);
          // 请求成功
          if (res.data.status == 0) {
            //数据不为空
            if (res.data.result.length != 0) {
              res.data.result.forEach((item,i)=>{
                item.showCancel = false
              })
              this.watchListData = res.data.result;
              this.watchCount = res.data.page_num;
              // 当前没有传来正在播放的视频
              if (
                this.$route.params.id == null ||
                this.$route.params.id == undefined
              ) {
                 this.active=res.data.result[0].ip_id;
                this.currentId = res.data.result[0].ip_id;
                this.currentUrl = res.data.result[0].url;
                this.copy_url = res.data.result[0].copy_url;
                this.collectif = res.data.result[0].collect_if;
                this.currentName = res.data.result[0].name;
                this.introduce = res.data.result[0].introduce;
                this.currentTag = res.data.result[0].tag_list;
                this.quanxian = res.data.result[0].commercial_licenses;
                this.currentDate = res.data.result[0].upload_time;
                // 修改完数据后立即操作dom
                this.$nextTick(() => {
                  this.setListVideo();
                  this.value ? this.player.play():''
                  // 监听播放结束
                  this.monitorEnded();
                });
              }
              // 当前有传来正在播放的视频
              else {
                for (let item of res.data.result) {
                  if (this.$route.params.id == item.ip_id) {
                    this.active=res.data.result[0].ip_id;
                    this.currentId = item.ip_id;
                    this.currentUrl = item.url;
                    this.copy_url = res.data.result[0].copy_url;
                    this.collectif = item.collect_if;
                    this.currentName = item.name;
                    this.introduce = item.introduce;
                    this.currentTag = item.tag_list;
                    this.quanxian = item.commercial_licenses;
                    this.currentDate = item.upload_time;
                    // 修改完数据后立即操作dom
                    this.$nextTick(() => {
                      this.setListVideo();
                      // 监听播放结束
                      this.monitorEnded();
                      setTimeout(() => {
                        this.player.play();
                      }, 0);
                    });
                    break;
                  }
                }
              }
            }
            // 数据为空
            else {
              this.watchCount = 0;
              this.currentId = "";
              this.currentUrl = "";
              this.copy_url = "";
              this.collectif = "";
              this.currentName = "";
              this.introduce = "";
              this.currentTag = [];
              this.quanxian = "";
              this.currentDate = "";
              this.watchListData = [];
              this.player = null;
            }
          }
          // 请求失败
          else {
          }
        })
        .catch((error) => console.log(error));
    },
    // 设置播放列表
    setListVideo() {
      // 设置大播放器的视频源
      this.player = new Player({
        id: "materialDetail",
        url: this.currentUrl,
        volume:0.5,
        fluid: true,
        videoInit: true,
        download: false,
        closeVideoDblclick: true,
        cssFullscreen: true,
        ignores: ["fullscreen", "replay"],
        playbackRate: [0.5, 0.75, 1, 1.5, 2],
        lastPlayTime:
          this.$route.params.currentTime == undefined
            ? 0
            : this.timeEvent(this.$route.params.currentTime),
      });
    },
    // 监听视频结束状态
    monitorEnded() {
      this.player.on("ended", () => {
        // console.log(this.value)
        if(this.value == true){
            // 顺序播放下一个视频
        for (let i = 0; i < this.watchListData.length; i++) {
          if (this.active == this.watchListData[i].ip_id) {
            var index = 1 + i;
            if (i == this.watchListData.length - 1) {
              index = 0;
            }
            this.active=this.watchListData[index].ip_id;
            this.player.src = this.watchListData[index].url;
            this.currentId = this.watchListData[index].ip_id;
            this.currentUrl = this.watchListData[index].url;
            this.copy_url = this.watchListData[index].copy_url;
            this.collectif = this.watchListData[index].collect_if;
            this.currentName = this.watchListData[index].name;
            this.introduce = this.watchListData[index].introduce;
            this.currentTag = this.watchListData[index].tag_list;
            this.quanxian = this.watchListData[index].commercial_licenses;
            this.currentDate = this.watchListData[index].upload_time;
            break;
          }
        }
        }
        else{
          this.player.pause()
        }

      });
    },
    // 销毁播放器
    destory() {
      if (this.player != null) {
        this.player.destroy(true);
      }
    },
    // 鼠标移入
    Select(id) {
      this.watchListData.forEach((item,i)=>{
        id == item.ip_id ? item.showCancel = true:''
      })
    },
    // 鼠标移出
    unSelect(id) {
       this.watchListData.forEach((item,i)=>{
        id == item.ip_id ? item.showCancel = false:''
      })
    },
    // 点击某一项
    Selected(id, url, copy_url,ifcollect, name, uptime, introduce, licenses, taglist) {
      this.active = id;
      this.currentId = id;
      this.currentUrl = url;
      this.copy_url = copy_url;
      this.collectif = ifcollect;
      this.player.src = url;
      this.currentName = name;
      this.currentDate = uptime;
      this.introduce = introduce;
      this.currentTag = taglist;
      this.quanxian = licenses;
      this.player.play();
    },
    // 删除某一项
    Delete(id) {
      let formData = new FormData();
      formData.append("ip_id", id);
      // this.destory()
      this.$http({
        url: "vw_material/del_watch_record",
        method: "POST",
        data: formData,
      })
        .then((res) => {
          // 删除成功
          if (res.data.status == 0) {
            this.$store.commit("deleteItem", true);

            // 请求观看列表
            // this.reload()
            this.getWatchList();
          }
        })
        .catch((error) => console.log(error));
    },
    // 去往详情页面
    gotoDetail(id) {
      console.log(id)
      const { href } = this.$router.resolve({
        name: "materialDetail",
        query: { ip_id: id, videoOrimg: "video" },
      });
      window.open(href, "_blank");
    },
    // 播放当前视频
    PlayVideo(id, url) {
      if (id != this.currentId) {
        let Video = document.getElementById("materialDetail").firstChild;
        Video.src = url;
        for (let item of this.watchListData) {
          if (id == item.ip_id) {
            this.currentId = item.ip_id;
            this.currentUrl = item.url;
            this.copy_url = item.copy_url;
            this.currentName = item.name;
            this.currentDate = item.upload_time;
            this.introduce = item.introduce;
            this.currentTag = item.tag_list;
            this.quanxian = item.commercial_licenses;
          }
        }
        this.player.play();
      }
    },
    // 从头开始
    restart() {
      if (this.player == null) {
      } else {
        this.currentId = this.watchListData[0].ip_id;
        this.currentUrl = this.watchListData[0].url;
        this.copy_url = this.watchListData[0].copy_url;
        this.player.src = this.watchListData[0].url;
        this.collectif = this.watchListData[0].collect_if;
        this.currentName = this.watchListData[0].name;
        this.introduce = this.watchListData[0].introduce;
        this.currentTag = this.watchListData[0].tag_list;
        this.quanxian = this.watchListData[0].commercial_licenses;
        this.currentDate = this.watchListData[0].upload_time;
      }
    },
    // 清空观看列表
    clearAll() {
      if (this.watchListData.length != 0) {
        this.clearAllDialog = true;
      }
    },
    // 全部清空对话框确认事件
    clearAllConfirm() {
      let formData = new FormData();
      formData.append("ip_id", -1);
      this.$http({
        url: "vw_material/del_watch_record",
        method: "POST",
        data: formData,
      })
        .then((res) => {
          // 删除成功
          if (res.data.status == 0) {
            // 请求观看列表
            this.getWatchList();
            this.clearAllDialog = false;
          }
        })
        .catch((error) => console.log(error));
    },
    // // 单击标签事件
    // clickTag() {
    //   console.log("单击标签事件");
    // },
    // 将时分秒转化秒
    timeEvent(e) {
      var time = e;
      var len = time.split(":");
      if (len.length == 3) {
        var hour = time.split(":")[0];
        var min = time.split(":")[1];
        var sec = time.split(":")[2];
        return Number(hour * 3600) + Number(min * 60) + Number(sec);
      }
      if (len.length == 2) {
        var min = time.split(":")[0];
        var sec = time.split(":")[1];
        return Number(min * 60) + Number(sec);
      }
      if (len.length == 1) {
        var sec = time.split(":")[0];
        return Number(sec);
      }
    },
  },
  created() {
    // 请求观看列表
    this.getWatchList();
  },
  mounted() {
    let video_boxW = $(".video_box").width();
    $(".video_box").css("height", video_boxW * 0.587 + "px");
    window.onresize = () => {
      let video_boxW = $(".video_box").width();
      $(".video_box").css("height", video_boxW * 0.587 + "px");
    };
  },
};
</script>
<style lang="scss" scoped>
.meterial_detail {
  .material_content {
    display: flex;
    justify-content: space-between;
    margin: 48px  5.4% 0 5.4%;
    .left_video{
      width: 64%;
    }
    .right_list{
      width: 33.5%;
      margin-top: -10px;
    }

    //视频
    .video_box {
      height: 50vh;
      display: flex;
      align-items: center;
      position: relative;
      background-color: #000000;
      &:hover {
        .hover_effect {
          display: flex;
        }
      }
      .hover_effect {
        position: absolute;
        z-index: 10000;
        right: 14px;
        top: 33px;
        display: none;
        .iconfont {
          padding: 4px;
          border-radius: 5px;
          background-color: rgba(0, 0, 0, 0.5);
          display: inline-block;
          margin-right: 5px;
          cursor: pointer;
        }
        .icon-shoucang {
          color: #ffa200;
        }
        .existed_tag {
          font-size: 12px;
          background-color: #2a9cff;
          color: #fff;
          border-radius: 2px;
          padding: 5px 9px;
          position: absolute;
          box-sizing: border-box;
        }
      }
    }
    // 视频底部信息
    .bottom_info {
      color: rgba(255, 255, 255, 0.9);
      margin-top: 2vh;
      .titleandButtom {
        .currentName{
             width: 37vw;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              display:block;
        }
        .currentName:hover {
          color: #2a9cff;
          cursor: pointer;
        }
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        margin-bottom: 6px;
        .gojianji {
          color: rgba(255, 255, 255, 0.9);
          background-color: #2a9cff;
          width: 108px;
          height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;

          border-color: #2a9cff;
        }
        span {
          font-size: 20px;
        }
      }
      .list_intro {
        position: relative;
        // height: 76px;
        overflow: hidden;
        margin-top: 6px;
        color: rgba(255, 255, 255, 0.6);
        .hide-articel-box {
          display: block;
          position: absolute;
          bottom: 0;
          text-align: center;
          color: rgba(255, 255, 255, 0.8);
          line-height: 24px;
          width: 100%;
          padding-top: 40px;
          background-image: -webkit-gradient(
            linear,
            left top,
            left bottom,
            from(rgba(255, 255, 255, 0)),
            color-stop(70%, #0f0f1e)
          );
          background-image: linear-gradient(
            -180deg,
            rgba(255, 255, 255, 0) 0,
            #0f0f1e 70%
          );
          cursor: pointer;
          z-index: 10;
        }
        .title {
          font-size: 14px;
        }
        i {
          font-size: 12px;
        }
      }

      p {
        font-size: 14px;
      }
      .scroll {
        overflow-y: auto;
        height: 9vh;
        .tag{
          cursor: pointer;
          background-color: rgba(62, 61, 76, 0.6);
          border-radius: 6px;
          display: inline-block;
          margin: 0 5px 5px 0;
          padding: 4px 12px;
        }
      }
      /*滚动条样式*/
      .scroll::-webkit-scrollbar {
        /*滚动条整体样式*/
        width: 4px; /*高宽分别对应横竖滚动条的尺寸*/
        height: 4px;
      }
      .scroll::-webkit-scrollbar-thumb {
        /*滚动条里面小方块*/
        border-radius: 5px;

        background: #b9bac9;
      }
      .scroll::-webkit-scrollbar-track {
        /*滚动条里面轨道*/

        border-radius: 0;
        background: #5e5d6e;
      }
    }
    // 右侧观看列表头部
    .top {
      color: rgba(255, 255, 255, 0.8);
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      .title{
        font-size: 16px;
      }
      /deep/.el-switch__label {
        color: rgba(255, 255, 255, 0.9);
      }
      /deep/.el-switch {
        height: auto;
        line-height: inherit;
        display: inline-block;
        .el-switch__core {
          border: 0px solid #dcdfe6;
          width: 35px !important;
          height: 15px;
        }
        .el-switch__core:after {
          background-color: #484658;
          top: -2px;
          width: 19px;
          height: 19px;
        }
      }
      /deep/.is-checked {
        .el-switch__core:after {
          background-color: #2a9cff;
        }
      }
      .el-dropdown-link {
        cursor: pointer;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5%;
      }
      .el-dropdown {
        color: rgba(255, 255, 255, 0.9);
      }
      .abled {
        cursor: pointer;
      }
      .disabled {
        opacity: 0.6;
        cursor: no-drop;
      }

      .start {
        height: 30px;
        line-height: 8px;
        background: #3e3d4c;
        border-radius: 16px;
        color: rgba(255, 255, 255, 0.9);
        opacity: 1;
        border: 0px;
      }
      .start1 {
        height: 30px;
        line-height: 8px;
        background: #3e3d4c;
        border-radius: 16px;
        color: rgba(255, 255, 255, 0.9);
        opacity: 0.6;
        border: 0px;
      }
    }
    // 播放列表容器
    .item_container {
      color: rgba(255, 255, 255, 0.9);
      height: 78vh;
      margin-top: 12px;
      overflow-y: auto;
      border: 2px solid #353542;
      overflow-x: hidden;
      //    播放列表
      .active_item{
        border: 2px solid #2A9CFF !important;
      }
      // .hover_item{
      //   border: 2px solid #1C558F;
      // }
      .item {
        cursor: pointer;
        display: flex;
        align-items: center;
        padding: 5px 3px 5px 9px;
        margin-bottom: 3px;
        box-sizing: border-box;
        height: 108px;
        position: relative;
        border: 2px solid transparent;
        border-radius: 6px;
        &:hover{
          border: 2px solid #1C558F;
        }
        .post_outer{
          display: flex;
          width: 100%;
        }
        .item_video {
          width: 138px;
          flex-shrink: 0;
          height: 87px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #000;
          overflow: hidden;
          position: relative;
          img {
            max-width: 100%;
            max-height: 100%;
            flex-shrink: 0;
          }
          .duration{
            position: absolute;
            bottom: 0;
            right: 0;
            background-color: rgba(0, 0, 0, 0.6);
            position: absolute;
            font-size: 12px;
            // border-radius: 4px;
            padding: 4px 6px;
          }
        }
        .item_info {
          margin-left: 10px;
          width: 62%;
          @media (max-width: 1550px){
            width: 58%;
          }
          .title {
            display: flex;
            justify-content: space-between;
            color: rgba(255, 255, 255, 0.8);
            .delete {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 30px;
              height: 30px;
              background: rgba(42, 156, 255, 0.6);
              border-radius: 2px;
              position: absolute;
              right: 0;
              top: 0;
            }
          }
          .context {
            font-size: 12px;
            margin-top: 6px;
            color: rgba(255, 255, 255, 0.6);
          }
          .tagList {
            margin-top: 12px;
            .tag{
              cursor: pointer;
              background-color: rgba(62, 61, 76, 0.6);
              border-radius: 6px;
              display: inline-block;
              margin: 0 5px 0 0;
              padding: 4px 12px;
            }
          }
        }
      }
    }

    // 内容区域滚动条样式
    .item_container::-webkit-scrollbar {
      /*滚动条整体样式*/
      width: 8px; /*高宽分别对应横竖滚动条的尺寸*/
      height: 4px;
    }
    .item_container::-webkit-scrollbar-thumb {
      /*滚动条里面小方块*/
      border-radius: 7px;
      background: #b9bac9;
    }
    .item_container::-webkit-scrollbar-track {
      /*滚动条里面轨道*/
      border-radius: 7px;
      background: #5e5d6e;
    }
  }
}
.material_dropdown {
  margin-top: 6px;
  margin-left: -8px;
  padding: 0;
  background-color: #3e3d4c;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  border: none;
  border-radius: 5px;
  .el-dropdown-menu__item {
    padding: 0 24px 0 8px;
    line-height: 38px;
    color: rgba(255, 255, 255, 0.9);
    font-size: 18px;
    &:hover {
      background-color: inherit;
      color: #2a9cff;
    }
  }
  .dropdown_active {
    color: #2a9cff;
  }
  /deep/.popper__arrow {
    display: none;
  }
}
.add_dropdown {
  position: absolute;
  top: 210px !important;
  margin-top: 6px;
  margin-left: -8px;
  padding: 0;
  background-color: #3e3d4c;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  border: none;
  border-radius: 5px;
  .el-dropdown-menu__item {
    padding: 0 24px 0 8px;
    line-height: 38px;
    color: rgba(255, 255, 255, 0.9);
    font-size: 18px;
    &:hover {
      background-color: inherit;
      color: #2a9cff;
    }
  }
  .dropdown_active {
    color: #2a9cff;
  }
  /deep/.popper__arrow {
    display: none;
  }
}
</style>

<style lang="scss">
.my_material_tooltip.el-tooltip__popper{
    padding: 6px 10px;
    // max-width: 10%;
    // line-height: 18px;
  }
.el-dialog.material-dialog {
  background-color: #3e3d4c;
  border-radius: 15px;
  box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.16);
  padding: 18px 21px 21px;
  .el-dialog__header {
    text-align: center;
    padding: 0;
    padding-bottom: 13px;
    .el-dialog__title {
      color: #fff;
      font-size: 16px;
      letter-spacing: 30px;
    }
    .el-dialog__headerbtn {
      .el-dialog__close {
        color: #fff;
        font-size: 18px;
      }
    }
  }
  .el-dialog__body {
    color: #fff;
    background-color: #2d2c39;
    font-size: 14px;
    padding: 38px 69px 0 69px;
    .radio_check {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .disable_radio {
        .el-radio__inner {
          background-color: rgba(255, 255, 255, 0.5);
          border: inherit;
        }
        .el-radio__label {
          color: rgba(255, 255, 255, 0.5);
        }
      }
      .el-radio {
        width: 50%;
        margin-right: 0;
        margin-bottom: 18px;
        color: #fff;
      }
      .el-radio__inner {
        width: 16px;
        height: 16px;
      }
      .is-checked {
        .el-radio__inner {
          width: 16px;
          height: 16px;
          border: 3px solid #fff;
          &::after {
            background-color: inherit;
          }
        }
      }
    }
  }
  .el-dialog__footer {
    padding: 20px 69px 45px 69px;
    background-color: #2d2c39;
    border-radius: 0px 0px 10px 10px;
    .dialog-footer {
      display: flex;
      justify-content: space-between;
      .el-button {
        background-color: #3e3d4c !important;
        border-radius: 20px;
        border: 1px solid #5e5f72;
        background-color: inherit;
        color: #fff;
        padding: 6px 15px;
      }
    }
  }
}
.el-dialog.clearAll-dialog {
  background-color: #3e3d4c;
  border-radius: 15px;
  box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.16);
  padding: 18px 21px 21px;
  .el-dialog__header {
    text-align: center;
    padding: 0;
    padding-bottom: 13px;
    .el-dialog__title {
      color: #fff;
      font-size: 16px;
      letter-spacing: 30px;
    }
    .el-dialog__headerbtn {
      .el-dialog__close {
        color: #fff;
        font-size: 18px;
      }
    }
  }
  .el-dialog__body {
    color: #fff;
    background-color: #2d2c39;
    font-size: 14px;
    padding: 38px 69px 1px 69px;
    .radio_check {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .el-radio {
        width: 50%;
        margin-right: 0;
        margin-bottom: 18px;
        color: #fff;
      }
      .el-radio__inner {
        width: 16px;
        height: 16px;
      }
      .is-checked {
        .el-radio__inner {
          width: 16px;
          height: 16px;
          border: 3px solid #fff;
          &::after {
            background-color: inherit;
          }
        }
      }
    }
  }
  .el-dialog__footer {
    padding: 20px 69px 45px 69px;
    background-color: #2d2c39;
    border-radius: 0px 0px 10px 10px;
    .dialog-footer {
      display: flex;
      justify-content: space-between;
      .el-button {
        background-color: #3e3d4c !important;
        border-radius: 20px;
        border: 1px solid #5e5f72;
        background-color: inherit;
        color: #fff;
        padding: 6px 15px;
      }
    }
  }
}

  .el-popper[x-placement^="bottom"] {
    margin-top: 2px;
    margin-left: 7px;
  }
  .el-dropdown-menu{
    padding:0px 0px;
    margin:0px 0px;
    background-color: #2D2C39;
    border: 0px solid #EBEEF5;

  }
  .el-dropdown-menu__item{
    color:rgba(255, 255, 255, 0.9);
    padding: 0 15px;
    line-height: 30px;
  }
  .el-dropdown-menu__item:not(.is-disabled):hover{
    color:#2A9CFF;
    background-color: transparent;
  }

</style>
